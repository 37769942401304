import React, { useEffect, useState } from 'react';
import {
  DateInput,
  TextInput,
  Edit,
  useEditController,
  SimpleForm,
  Show,
  SimpleShowLayout,
  TopToolbar,
  useQuery,
  useNotify,
  BooleanInput,
  Toolbar,
  SaveButton,
  useRefresh,
  SelectInput,
  required,
} from 'react-admin';
import { AppBar, Tabs, Tab } from '@material-ui/core';

import { UserUpcomingAppointment } from '../user/user-upcoming-appointments';
import { UserCompletedAppointment } from '../user/user-completed-appointments';
import { UserCancelledAppointment } from '../user/user-cancelled-appointments';
import { UserReviews } from '../reviews/user-reviews';
import { UserTips } from '../tips/user-tips';
import { NoTitle } from '../utils';
import { GENDER_OPTIONS, TEAM_MEMBERS } from '../utils/constants';
import { useParams } from 'react-router-dom';
import { TeamMembersTabs } from './tabs';
import { customDataProvider } from '../data-provider';

const a11yProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

export const TeamMemberEdit = props => {
  const [workLocations, setWorkLocations] = useState([]);
  const [appointmentIndex, setAppointmentIndex] = React.useState(0);
  const { therapistId } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();
const validateRequired = [required()];

  useEffect(() => {
    customDataProvider.getList('userlocations').then(data => {
      setWorkLocations(data.data);
    });
  }, []);

  const getLocationName = locationId => {
    const location =
      workLocations &&
      workLocations.find(loc => loc.location_id === locationId);
    return location && location.location.name;
  };

  const handleChange = (event, newValue) => {
    setAppointmentIndex(newValue);
  };

  const controllerProps = useEditController({
    basePath: `/employee/${therapistId}`,
    permissions: 'admin',
    resource: 'employee',
    id: therapistId,
  });
  const therapistDetails = controllerProps.record;

  const handleEmployeeUpdate = formValues => {
    const {
      bio,
      pregnancy,
      license_number,
      license_expiration,
      first_name,
      last_name,
      phone,
      gender
    } = formValues;
    let payload = {
      id: therapistDetails?.id,
      bio,
      pregnancy,
      license_number,
      license_expiration,
      first_name,
      last_name,
      phone,
      gender
    };
    customDataProvider.update('employee', { data: payload }).then(
      () => {
        notify('Therapist updated!');
      },
      err => {
        notify(
          err.response ? err.response?.data?.message : err.message,
          'error',
        );
        refresh();
      },
    );
  };

  const EditToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Save" onSave={handleEmployeeUpdate} />
    </Toolbar>
  );

  const render = () => (
    <>
      <TeamMembersTabs />
      <div className="details-display">
        <Edit
          resource={'employee'}
          basePath={`/employee`}
          id={therapistId}
          title="Edit Team Member"
          undoable={false}
          {...props}
        >
          <SimpleForm
            className="simpleform-details-display"
            toolbar={<EditToolbar />}
          >
            <TextInput label="First Name" source="first_name" />
            <TextInput label="Last Name" source="last_name" />
            <TextInput label="Phone Number" source="phone" />
                    <SelectInput
          source="gender"
          label="Gender"
          optionText="name"
          choices={GENDER_OPTIONS}
          validate={validateRequired}
        />
            <TextInput label="Biography" multiline source="bio" />
            <TextInput label="License Number" source="license_number" />
            <DateInput label="License Expiration" source="license_expiration" />
            <BooleanInput
              label="Prenatal Certification"
              options={{
                id: 'pregnancy',
              }}
              source="pregnancy"
            />
          </SimpleForm>
        </Edit>
        <Show
          title={<NoTitle />}
          {...props}
          actions=""
          resource={'employee'}
          basePath={`/employee`}
          id={therapistId}
        >
          <SimpleShowLayout className="right-panel">
            <div className="pt-2">
              <div className="label">Email</div>
              <div>{therapistDetails?.email}</div>
              <div className="label">Average Rating</div>
              <div>
                {therapistDetails?.average_rating
                  ? therapistDetails?.average_rating.toFixed(2)
                  : 0}
              </div>
              <div className="label">Location Name</div>
              <div>{getLocationName(therapistDetails?.location_id)}</div>
            </div>
          </SimpleShowLayout>
        </Show>
      </div>
      <Show
        title={<NoTitle />}
        {...props}
        resource={'employee'}
        basePath={`/employee`}
        id={therapistId}
        actions={<TopToolbar />}
      >
        <SimpleShowLayout>
          <AppBar color="transparent" position="static">
            <Tabs
              className="biggerTabs"
              value={appointmentIndex}
              onChange={handleChange}
              aria-label="Appointments Bar"
            >
              <Tab label="Upcoming Appointments" {...a11yProps(0)} />
              <Tab label="Completed Appointments" {...a11yProps(1)} />
              <Tab label="Cancelled Appointments" {...a11yProps(2)} />
            </Tabs>
          </AppBar>

          <TabPanel value={appointmentIndex} index={0}>
            <UserUpcomingAppointment card={TEAM_MEMBERS} {...props} />
          </TabPanel>
          <TabPanel value={appointmentIndex} index={1}>
            <UserCompletedAppointment card={TEAM_MEMBERS} {...props} />
          </TabPanel>
          <TabPanel value={appointmentIndex} index={2}>
            <UserCancelledAppointment card={TEAM_MEMBERS} {...props} />
          </TabPanel>
        </SimpleShowLayout>
      </Show>
      <Show
        title={<NoTitle />}
        {...props}
        resource={'employee'}
        basePath={`/employee`}
        id={therapistId}
        actions={<TopToolbar />}
      >
        <SimpleShowLayout>
          <AppBar color="transparent" position="static">
            <Tabs className="biggerTabs" value={0} aria-label="Reviews">
              <Tab label="Reviews" {...a11yProps(0)} />
            </Tabs>
          </AppBar>
          <TabPanel value={0} index={0}>
            <UserReviews card={TEAM_MEMBERS} {...props} />
          </TabPanel>
        </SimpleShowLayout>
      </Show>
      <Show
        title={<NoTitle />}
        {...props}
        resource={'employee'}
        basePath={`/employee`}
        id={therapistId}
        actions={<TopToolbar />}
      >
        <SimpleShowLayout>
          <AppBar color="transparent" position="static">
            <Tabs className="biggerTabs" value={0} aria-label="Reviews">
              <Tab label="Tips" {...a11yProps(0)} />
            </Tabs>
          </AppBar>
          <TabPanel value={0} index={0}>
            <UserTips card={TEAM_MEMBERS} {...props} />
          </TabPanel>
        </SimpleShowLayout>
      </Show>
    </>
  );

  return therapistId ? render() : <div />;
};
