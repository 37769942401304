export const DATE_FORMAT = {
  MONTH_DAY_YEAR: 'mdy',
  DAY_MONTH_YEAR: 'dmy',
  YEAR_MONTH_DAY: 'ymd',
};

export const GIFT_CODE_LENGTH = 8;

export const toasterConfig = {
  position: 'top-right',
  reverseOrder: false,
  toastOptions: {
    // Define default options
    style: {
      margin: '40px',
      background: '#363636',
      color: '#fff',
      zIndex: 1,
    },
    duration: 5000,
    // Default options for specific types
    success: {
      duration: 3000,
      theme: {
        primary: 'green',
        secondary: 'black',
      },
    },
  },
};

export const DELIVERY_MODE = {
  EMAIL: 'email',
  PRINT: 'print',
  SHOP: 'shop',
  SHIP: 'ship',
};

export const PREFERENCES_REASON_OPTIONS = [
  { title: 'Relaxation', selected: false },
  { title: 'Stress Relief', selected: false },
  { title: 'Pain Reduction', selected: false },
  { title: 'Health & Wellness', selected: false },
  { title: 'Sports Recovery', selected: false },
  { title: 'Pregnancy', selected: false },
];

export const MASSAGE_REASON_OPTIONS = [
  {
    label: 'PAIN REDUCTION',
    value: 'Pain Reduction',
  },
  {
    label: 'STRESS RELIEF',
    value: 'Stress Relief',
  },
  {
    label: 'RELAXATION',
    value: 'Relaxation',
  },
  {
    label: 'HEALTH & WELLNESS',
    value: 'Health & Wellness',
  },
  {
    label: 'SPORTS RECOVERY',
    value: 'Sports Recovery',
  },
  {
    label: 'PREGNANCY',
    value: 'Pregnancy',
  },
];

export const PREFERENCES_BACK_OPTIONS_MINI = [
  {
    title: 'Head',
    key: 'head',
    apiKey: 'focus_backside_head',
    apiPKey: 'pressure_backside_head',
  },
  {
    title: 'Neck',
    key: 'neck',
    apiKey: 'focus_backside_neck',
    apiPKey: 'pressure_backside_neck',
  },
  {
    title: 'Shoulders',
    key: 'shoulders',
    apiKey: 'focus_backside_shoulders',
    apiPKey: 'pressure_backside_shoulders',
  },
  {
    title: 'Upper Back',
    key: 'upperback',
    apiKey: 'focus_backside_upper-back',
    apiPKey: 'pressure_backside_upper-back',
  },
  {
    title: 'Arms, Hands',
    key: 'arms',
    apiKey: 'focus_frontside_arms-hands',
    apiPKey: 'pressure_frontside_arms-hands',
  },
  {
    title: 'Feet',
    key: 'feet',
    apiKey: 'focus_frontside_feet',
    apiPKey: 'pressure_frontside_feet',
  },
];

export const PREFERENCES_BACK_OPTIONS = [
  {
    title: 'Neck',
    key: 'neck',
    apiKey: 'focus_backside_neck',
    apiPKey: 'pressure_backside_neck',
  },
  {
    title: 'Shoulders',
    key: 'shoulders',
    apiKey: 'focus_backside_shoulders',
    apiPKey: 'pressure_backside_shoulders',
  },
  {
    title: 'Upper Back',
    key: 'upperback',
    apiKey: 'focus_backside_upper-back',
    apiPKey: 'pressure_backside_upper-back',
  },
  {
    title: 'Lower Back',
    key: 'lowerback',
    apiKey: 'focus_backside_lower-back',
    apiPKey: 'pressure_backside_lower-back',
  },
  {
    title: 'Hip & Glutes',
    key: 'hips',
    apiKey: 'focus_backside_hips-glutes',
    apiPKey: 'pressure_backside_hips-glutes',
  },
  {
    title: 'Hamstrings',
    key: 'hams',
    apiKey: 'focus_backside_hamstrings',
    apiPKey: 'pressure_backside_hamstrings',
  },
  {
    title: 'Calves',
    key: 'calves',
    apiKey: 'focus_backside_calves',
    apiPKey: 'pressure_backside_calves',
  },
];

export const PREFERENCES_FRONT_OPTIONS = [
  {
    title: 'Head',
    apiKey: 'focus_frontside_head',
    apiPKey: 'pressure_frontside_head',
    key: 'head',
  },
  {
    title: 'Face & Jaw',
    apiKey: 'focus_frontside_face-jaw',
    apiPKey: 'pressure_frontside_face-jaw',
    key: 'face',
  },
  {
    title: 'Pecs',
    apiKey: 'focus_frontside_pecs',
    apiPKey: 'pressure_frontside_pecs',
    key: 'pecs',
  },
  {
    title: 'Arms & Hands',
    apiKey: 'focus_frontside_arms-hands',
    apiPKey: 'pressure_frontside_arms-hands',
    key: 'arms',
  },
  {
    title: 'Quads',
    apiKey: 'focus_frontside_quads',
    apiPKey: 'pressure_frontside_quads',
    key: 'quads',
  },
  {
    title: 'Feet',
    apiKey: 'focus_frontside_feet',
    apiPKey: 'pressure_frontside_feet',
    key: 'feet',
  },
];

export const PREFERENCES_EXTRAS = [
  {
    title: 'Oil or lotion?',
    apiKey: 'oil_lotion',
    values: [
      { title: 'oil', value: 'OIL' },
      { title: 'lotion', value: 'LOTION', selected: true },
    ],
    modalTitle: 'OILS AND LOTIONS',
    modalText: 'All our oils and lotions are natural and paraben-free.',
    modelSegment: 'Oil Lotion Tooltip',
  },
  {
    title: 'Bed heated?',
    apiKey: 'heated_bed',
    values: [
      { title: 'yes', selected: true, value: 1 },
      { title: 'no', value: 0 },
    ],
    modalTitle: 'HEATED BED',
    modalText:
      "If you select yes, your bed will be set to medium heat (our most preferred heat setting). Your therapist will check-in with you a few minutes into your massage to see if you want more, less or if it's just right.",
    modelSegment: 'Bed Heated Tooltip',
  },
  {
    title: 'Heat thearapy?',
    apiKey: 'heat_therapy',
    values: [
      { title: 'yes', selected: true, value: 1 },
      { title: 'no', value: 0 },
    ],
    modalTitle: 'WHAT IS HEAT THERAPY?',
    modalText:
      'Heat therapy is the application of heat pads to areas of the body with tense muscles and localized pain in order to increase blood-flow, soften tissues in preparation for massage, and promote relaxation',
    modelSegment: 'Heat Therapy Tooltip',
  },
];

export const PREFERENCES_DROPDOWN_OPTIONS = [
  { value: 'USUAL', label: 'USUAL', color: '#333333' },
  { value: 'MORE', label: 'MORE', color: '#37777e' },
  { value: 'AVOID', label: 'AVOID', color: '#e57373' },
];

export const PREFERENCES_DROPDOWN_OPTIONS_PRESSURE = [
  { value: 'USUAL', label: 'USUAL', color: '#8dd1d8' },
  { value: 'MORE', label: 'MORE', color: '#37777e' },
  { value: 'LESS', label: 'LESS', color: '#bbeef3' },
];

export const MASSAGE = 'MASSAGE';
export const FRONT = 'FRONT';
export const BACK = 'BACK';

export const APPOINTMENT_STATUS = {
  CHECK_IN: 'checkedin',
  CONFIRMED: 'confirmed',
  COMPLETE: 'complete',
  CANCELLED: 'cancelled',
};

export const AROMA_THERAPY_OPTIONS = [
  { value: '', label: 'NONE' },
  { value: 'DO', label: 'GRAPEFRUIT' },
  { value: 'HEAL', label: 'ROSEMARY' },
  { value: 'LIVE', label: 'BERGAMOT' },
  { value: 'SLEEP', label: 'LAVENDER' },
];
export const LIGHTING_OPTIONS = [
  { value: 'Dimmest', label: 'Dimmest' },
  { value: 'dimmer', label: 'Dimmer' },
  { value: 'dim', label: 'Dim' },
];
export const TEMPERATURE_OPTIONS = [
  { value: 68, label: '68' },
  { value: 69, label: '69' },
  { value: 70, label: '70' },
  { value: 71, label: '71' },
  { value: 72, label: '72' },
  { value: 73, label: '73' },
  { value: 74, label: '74' },
  { value: 75, label: '75' },
];
export const MUSIC_OPTIONS = [
  { value: '', label: 'NONE' },
  { value: 'INDIE', label: 'INDIE' },
  { value: 'PIANO', label: 'PIANO' },
  { value: 'VIBES', label: 'VIBES' },
  { value: 'ISLAND', label: 'ISLAND' },
  { value: 'WAVES', label: 'WAVES' },
  { value: 'ZEN', label: 'ZEN' },
];
export const OIL_OR_LOTION_OPTIONS = [
  { value: 'LOTION', label: 'LOTION' },
  { value: 'OIL', label: 'OIL' },
];
export const BED_HEAT_THERAPY_OPTIONS = [
  { value: 0, label: 'No' },
  { value: 1, label: 'Yes' },
];
export const PERCUSSION_THERAPY_OPTIONS = [
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
  { value: null, label: 'None Selected' },
];

export const GUESTS = 'guests';
export const TEAM_MEMBERS = 'team_members';

export const HEARD_ABOUT_US = [
  { id: 'Friend/Family', name: 'Friend/Family' },
  { id: 'Facebook/Instagram', name: 'Facebook/Instagram' },
  { id: 'Google/Online Search', name: 'Google/Online Search' },
  { id: 'Walk-in/Drive By', name: 'Walk-in/Drive By' },
  { id: 'Drybar', name: 'Drybar' },
  {
    id: 'Press Article, Magazine or Podcast',
    name: 'Press Article, Magazine or Podcast',
  },
  { id: 'Local Business', name: 'Local Business' },
  { id: 'Pop-Up Event', name: 'Pop-Up Event' },
  { id: 'Corporate Partnership', name: 'Corporate Partnership' },
  { id: 'Healthcare Provider', name: 'Healthcare Provider' },
  { id: 'School Partnership', name: 'School Partnership' },
  { id: 'Residential', name: 'Residential' },
  { id: 'Coffee Shop', name: 'Coffee Shop' },
  { id: 'Car Flyer', name: 'Car Flyer' },
  { id: 'Hotel', name: 'Hotel' },
  { id: 'Billboard', name: 'Billboard' },
  { id: 'Other', name: 'Other' },
];

export const REVIEW_STATUS = [
  { id: '0', name: 'Rejected' },
  { id: '1', name: 'Approved' },
  { id: '2', name: 'Pending' },
];

export const APPOINTMENT_SLOTS = [
  { id: '08:00', name: '08:00am' },
  { id: '08:15', name: '08:15am' },
  { id: '08:30', name: '08:30am' },
  { id: '08:45', name: '08:45am' },
  { id: '09:00', name: '09:00am' },
  { id: '09:15', name: '09:15am' },
  { id: '09:30', name: '09:30am' },
  { id: '09:45', name: '09:45am' },
  { id: '10:00', name: '10:00am' },
  { id: '10:15', name: '10:15am' },
  { id: '10:30', name: '10:30am' },
  { id: '10:45', name: '10:45am' },
  { id: '11:00', name: '11:00am' },
  { id: '11:15', name: '11:15am' },
  { id: '11:30', name: '11:30am' },
  { id: '11:45', name: '11:45am' },
  { id: '12:00', name: '12:00pm' },
  { id: '12:15', name: '12:15pm' },
  { id: '12:30', name: '12:30pm' },
  { id: '12:45', name: '12:45pm' },
  { id: '13:00', name: '01:00pm' },
  { id: '13:15', name: '01:15pm' },
  { id: '13:30', name: '01:30pm' },
  { id: '13:45', name: '01:45pm' },
  { id: '14:00', name: '02:00pm' },
  { id: '14:15', name: '02:15pm' },
  { id: '14:30', name: '02:30pm' },
  { id: '14:45', name: '02:45pm' },
  { id: '15:00', name: '03:00pm' },
  { id: '15:15', name: '03:15pm' },
  { id: '15:30', name: '03:30pm' },
  { id: '15:45', name: '03:45pm' },
  { id: '16:00', name: '04:00pm' },
  { id: '16:15', name: '04:15pm' },
  { id: '16:30', name: '04:30pm' },
  { id: '16:45', name: '04:45pm' },
  { id: '17:00', name: '05:00pm' },
  { id: '17:15', name: '05:15pm' },
  { id: '17:30', name: '05:30pm' },
  { id: '17:45', name: '05:45pm' },
  { id: '18:00', name: '06:00pm' },
  { id: '18:15', name: '06:15pm' },
  { id: '18:30', name: '06:30pm' },
  { id: '18:45', name: '06:45pm' },
  { id: '19:00', name: '07:00pm' },
  { id: '19:15', name: '07:15pm' },
  { id: '19:30', name: '07:30pm' },
  { id: '19:45', name: '07:45pm' },
  { id: '20:00', name: '08:00pm' },
  { id: '20:15', name: '08:15pm' },
  { id: '20:30', name: '08:30pm' },
  { id: '20:45', name: '08:45pm' },
  { id: '21:00', name: '09:00pm' },
];

export const USER_ROLES = [
  { id: 'admin', name: 'Admin' },
  { id: 'therapist', name: 'Therapist' },
  { id: 'breakroom', name: 'Breakroom' },
  { id: 'front_desk', name: `Maitre D'` },
  { id: 'gss', name: 'Guest Experience' },
  { id: 'shop_manager', name: 'General Manager' },
  { id: 'operating-partner', name: 'Operating Partner' },
  { id: 'super-admin', name: 'Super Admin' },
];

export const USER_STATUS = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'Inactive' },
];

export const PREFERENCES_CUSTOMER_PRESSURE = {
  CUSTOMER_PRESSURE: 'customer_pressure',
};

export const TIMEZONES = {
  LA: 'America/Los_Angeles',
};

export const APPOINTMENT_REFUND = [
  {
    id: 'Reversed Cancellation Fee',
    name: 'Reversed Cancellation Fee',
  },
  {
    id: 'Unable to Fulfill Service Request',
    name: 'Unable to Fulfill Service Request',
  },
  {
    id: 'Poor Massage Quality',
    name: 'Poor Massage Quality',
  },
  {
    id: 'Shop Closure',
    name: 'Shop Closure',
  },
  { id: 'Other', name: 'Other' },
];

export const MEMBERSHIP_REFUND = [
  {
    id: 'Intended to Cancel Membership',
    name: 'Intended to Cancel Membership',
  },
  {
    id: 'Poor Massage Quality',
    name: 'Poor Massage Quality',
  },
  { id: 'Other', name: 'Other' },
];

export const TIPS_REFUND = [
  {
    id: 'Poor Massage Quality',
    name: 'Poor Massage Quality',
  },
  { id: 'Other', name: 'Other' },
];

export const WAIVE_CANCELLATION_WINDOW_IN_HOURS = 12;

export const USER_ROLES_PERMISSION = {
  'operating-partner': ['therapist', 'breakroom', 'front_desk', 'shop_manager'],
  shop_manager: ['therapist', 'breakroom', 'front_desk'],
  admin: [
    'admin',
    'therapist',
    'breakroom',
    'front_desk',
    'shop_manager',
    'operating-partner',
    'gss',
  ],
  'super-admin': [
    'super-admin',
    'admin',
    'therapist',
    'breakroom',
    'front_desk',
    'shop_manager',
    'operating-partner',
    'gss',
  ],
};

export const MEMBERSHIP_CANCEL_REASON = [
  {
    id: `Didn't enjoy my experiance`,
    name: `Didn't enjoy my experiance`,
  },
  {
    id: `I'm not using it enough`,
    name: `I'm not using it enough`,
  },
  {
    id: 'Too expensive',
    name: 'Too expensive',
  },
  {
    id: `I'm moving`,
    name: `I'm moving`,
  },
  { id: 'Other', name: 'Other' },
];

export const FRANCHISE_PHASES = {
  PHASE1: 'phase1',
  PHASE2: 'phase2',
  PHASE3: 'phase3',
};

export const MEMBERSHIP_STATUS = {
  isAwaiting: 'awaiting',
};

export const giftTypeChoice = [
  { id: 'points', name: 'Squeeze Bucks' },
  { id: 'membership', name: 'Membership' },
];

export const membershipDurationChoice = [
  { id: '3', name: '3 Months' },
  { id: '6', name: '6 Months' },
  { id: '12', name: '12 Months' },
];

export const membershipTypeChoice = [
  { id: '50minV2', name: 'Mid' },
  { id: '80minV2', name: 'Main' },
];

export const statusOptions = [
  { id: 'paid', name: 'Paid' },
  { id: 'shipped', name: 'Shipped' },
  { id: 'redeemed', name: 'Redeemed' },
  { id: 'cancelled', name: 'Cancelled' },
  { id: 'emailed', name: 'Emailed' },
];

export const MEMBERSHIP_TYPES = [
  { id: '50minv2', name: 'Regular - Mid' },
  { id: '80minv2', name: 'Regular - Main' },
  { id: '50minv2_founding', name: 'Founding - Mid' },
  { id: '80minv2_founding', name: 'Founding - Main' },
];

export const MEMBERSHIP_ACTION_NAMES = [
  { id: '50minv2', name: 'downgrade' },
  { id: '80minv2', name: 'upgrade' },
  { id: '50minv2_founding', name: 'downgrade' },
  { id: '80minv2_founding', name: 'upgrade' },
];

export const MEMBERS_REPORT_STATUS = [
  {id: 'all', name:'All'},
  { id: 'active', name: 'Active' },
  { id: 'paused', name: 'Paused' },
  { id: 'cancelled', name: 'Cancelled' },
  { id: 'awaiting', name: 'Awaiting' },
  { id: 'toBeCancelled', name: 'To be Cancelled' },
  { id: 'toBePaused', name: 'To be Paused' },
];

export const GENDER_OPTIONS = [
  { id: 0, name: 'Male' },
  { id: 1, name: 'Female' },
];