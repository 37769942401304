import React, { useState } from 'react';
import {
  ListContextProvider,
  Datagrid,
  FunctionField,
  useQuery,
  Loading,
  Pagination,
  Link,
} from 'react-admin';
import { keyBy } from 'lodash';
import { getFormattedDate_T, getMembershipStatus, renderGuestName } from '../utils';

const MembersReport = ({ filters, otherFilters }) => {
  const [page, setPage] = useState(1);
  const [ perPage, setPerPage ] = useState( 10 );
  const { clear } = otherFilters;
  const _otherFilters = {
    ...otherFilters,
    clear: null
  }

  const { data, total, loading } = useQuery( {
    type: 'getList',
    resource: 'members-report',
    payload: {
      filters: { ...filters, ..._otherFilters, csv: false },
      pagination: { page: clear ? 1 : page, perPage: clear ? 10 : perPage },
    },
  });

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[100]} {...props} />
  );

  if (loading) return <Loading />;
  return (
    <>
      <b>Members Report</b>

      <ListContextProvider
        value={{
          resource: 'report',
          basePath: '/report',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          setPage,
          perPage,
          setPerPage,
        }}
        pagination={<PostPagination />}
        perPage={perPage}
      >
        <Datagrid>
          <FunctionField label="ID" render={ record => record.user_id } sortable />
          <FunctionField
            label="Guest"
            render={record => renderGuestName(record)}
          />
          <FunctionField
            label="Started At"
            render={record => getFormattedDate_T(record.started_at) || 'NA'}
          />
          <FunctionField
            label="Paused At"
            render={record => getFormattedDate_T(record.paused_at) || 'NA'}
          />
          <FunctionField
            label="Resume At"
            render={record => getFormattedDate_T(record.resume_at) || 'NA'}
          />
          <FunctionField
            label="Cancelled At"
            render={record => getFormattedDate_T(record.cancelled_at) || 'NA'}
          />
          <FunctionField
            label="Home Location"
            render={record => record.home_location}
          />
          <FunctionField
            label="Type"
            render={record => getMembershipStatus(record)}
          />
          <FunctionField label="Sbs" render={record => `$${record.sbs}`} />
          <FunctionField label="Status" render={record => record.status} />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
export default MembersReport;
