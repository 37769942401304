import React, { useState } from 'react';
import {
  ListContextProvider,
  Datagrid,
  FunctionField,
  useQuery,
  Loading,
  Pagination,
} from 'react-admin';
import { keyBy } from 'lodash';
import { getMembershipStatus, renderGuestName } from '../utils';
import moment from 'moment';

const DemosReport = ({ reportFilters }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { location_id, clear } = reportFilters;
  const { data, total, loading } = useQuery({
    type: 'getList',
    resource: 'demos-report',
    payload: {
      filters: { location_id },
      pagination: { page: clear ? 1 : page, perPage: clear ? 10 : perPage },
    },
  });

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  );

  if (loading) return <Loading />;
  return (
    <>
      <b>Demos Report</b>

      <ListContextProvider
        value={{
          resource: 'report',
          basePath: '/report',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          setPage,
          perPage,
          setPerPage,
        }}
        sort={{ field: 'guest_name', order: 'ASC' }}
      >
        <Datagrid>
          <FunctionField label="ID" render={ record => record.guestId } sortable={ true } />
          <FunctionField label="Guest" render={record => renderGuestName(record)} sortable/>
          <FunctionField label="Birthday" render={record => moment(record.dob).format('MM/DD/YYYY')} />
          <FunctionField
            label="Heard about Us"
            render={record => record.heard_about_us || 'NA'}
          />
          <FunctionField
            label="Membership Status"
            render={record => record.status}
          />
          <FunctionField
            label="Membership Type"
            render={record => getMembershipStatus(record)}
          />

          <FunctionField
            label="Completed Appts"
            render={record => record.completedAppointmnet}
          />
          <FunctionField
            label="Upcoming Appts"
            render={record => record.upcomingAppointmnet}
          />
          <FunctionField label="Avg Days" render={record => record.avg_days || 'NA'} />
          <FunctionField
            label="Avg Ratings"
            render={record => record.avg_rating || 'NA' }
          />
          <FunctionField
            label="Zip Code"
            render={record => record.zip || 'NA' }
          />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
export default DemosReport;
