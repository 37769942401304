import React from 'react';
import {
  Datagrid,
  ListContextProvider,
  TextField,
  useQuery,
  Pagination,
  FunctionField,
  Loading,
} from 'react-admin';
import { keyBy } from 'lodash';
import { GUESTS, MEMBERSHIP_REFUND, TIPS_REFUND } from '../utils/constants';
import { getUtcToLocalDateTime, getFormattedCurrency } from '../utils';
import { Refund } from './refund';

export const UserTransactionsHistory = ({ card, entity_type, ...props }) => {
  const filters =
    card === GUESTS
      ? { user_id: props.id, entity_type }
      : { therapist_id: props.id };

  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [showRefund, setShowRefund] = React.useState(false);
  const [transaction, setTransaction] = React.useState(null);

  let { data, total, loading, refetch } = useQuery({
    type: 'getList',
    resource: 'allTransactions',
    payload: { pagination: { page, perPage }, filters },
  });

  const handleRefundClick = record => {
    setTransaction(record);
    setShowRefund(true);
  };

  const showRefundButton = record => {
    if (
      record?.reason !== 'Refund' &&
      ['membership', 'tips'].includes(entity_type)
    ) {
      const show =
        Math.abs(record?.refunded_amount) === 0 ||
        Math.abs(record?.refunded_amount) < Math.abs(record?.amount);
      return show ? (
        <button
          className="button-blue"
          onClick={() => {
            handleRefundClick(record);
          }}
        >
          Refund
        </button>
      ) : (
        ''
      );
    }
  };

  const showCodeField = ['membership', 'gift'].includes(entity_type);

  const showReason = record => {
    return record?.reason && `${record?.reason} - ${record?.refund_reason}`;
  };

  if (loading) return <Loading />;

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25]} {...props} />
  );

  return (
    <>
      <Refund
        showRefund={showRefund}
        setShowRefund={setShowRefund}
        transaction={transaction}
        refetch={refetch}
        refundReasons={entity_type === 'tips' ? TIPS_REFUND : MEMBERSHIP_REFUND}
      />
      <ListContextProvider
        value={{
          resource: 'allTransactions',
          basePath: `/guest/${props.id}`,
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          perPage,
          setPerPage,
          setPage,
        }}
        // pagination={<PostPagination />}
        perPage={perPage}
      >
        <Datagrid rowClick="edit">
          <FunctionField
            label="Date"
            render={record => getUtcToLocalDateTime(record?.created_at)}
          />
          <FunctionField
            label="Amount"
            render={record =>
              getFormattedCurrency(record?.amount, record.entity_type)
            }
          />
          {showCodeField && <FunctionField
            label="Code"
            render={record =>
             record.coupon_applied ? record.coupon_applied.toUpperCase() : ''
            }
          />}
          <TextField source="entity_type" label="Type" />
          <FunctionField label="Reason" render={record => showReason(record)} />
          <FunctionField
            render={record =>
              record.entity_type === 'membership'
                ? record.original_txn_id
                  ? record.original_txn_id
                  : record.id
                : record.entity_ref_id
            }
            label="ID"
          />
          <FunctionField render={record => showRefundButton(record)} />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
