import { client } from '../services/api-client';
import { constructUrl } from '../utils/api';

export const validatePromocode = async params =>
{        
  const url = constructUrl('coupon/validate', { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data: params,
    });
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

