import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  CheckboxGroupInput,
  DateInput,
  required,
  SelectInput,
  useNotify,
  BooleanInput,
} from 'react-admin';
import '../user-styles.css';
import { getFormattedDate, getLocations, getRoles } from '../utils';
import { GENDER_OPTIONS, USER_ROLES } from '../utils/constants';
import history from '../history';

const validateRequired = [required()];

export const TeamMemberCreate = props => {
  const [selectedRole, setSelectedRole] = useState([]);
  const notify = useNotify();
  const role = localStorage.getItem('role');

  const workLocations = JSON.parse(localStorage.getItem('userWorkLocations'));

  const todayDate = getFormattedDate(new Date());

  const onSuccess = () => {
    history.push('/employee');
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm initialValues={{ gender: 1, role: 'admin' }}>
        <TextInput
          source="first_name"
          label="First Name"
          validate={validateRequired}
        />
        <TextInput
          source="last_name"
          label="Last Name"
          validate={validateRequired}
        />
        <TextInput source="email" label="Email" validate={validateRequired} />
        {/* <DateInput source="dob" label="DOB" validate={validateRequired} /> */}
        <TextInput source="phone" label="Phone" validate={validateRequired} />
        <SelectInput
          source="gender"
          label="Gender"
          optionText="name"
          choices={GENDER_OPTIONS}
          validate={validateRequired}
        />
        <SelectInput
          source="role"
          optionText="name"
          choices={getRoles(role, USER_ROLES)}
          label="Role"
          onChange={e => setSelectedRole(e.target.value)}
          validate={validateRequired}
        />
        <CheckboxGroupInput
          source="locations"
          validate={validateRequired}
          choices={getLocations(workLocations)}
        />
        <TextInput
          source="service_ref_id"
          label="Service Reference Id"
          validate={validateRequired}
        />
        {selectedRole === 'therapist' && (
          <TextInput
            multiline
            source="bio"
            label="Bio"
            validate={validateRequired}
          />
        )}
        {/* {selectedRole === 'therapist' && (
          <TextInput
            source="massage_labor_rate"
            label="Massage Labor Rate"
            validate={validateRequired}
          />
        )} */}
        {/* {selectedRole === 'therapist' && (
          <TextInput
            source="admin_labor_rate"
            label="Admin Labor Rate"
            validate={validateRequired}
          />
        )} */}
        {selectedRole === 'therapist' && (
          <BooleanInput
            label="Prenatal Certified?"
            options={{
              id: 'pregnancy',
            }}
            source="pregnancy"
          />
        )}
        {selectedRole === 'therapist' && (
          <TextInput
            source="license_number"
            label="License Number"
            validate={validateRequired}
          />
        )}
        {selectedRole === 'therapist' && (
          <DateInput
            source="license_expiration"
            defaultValue=""
            label="License Expiration"
            validate={validateRequired}
          />
        )}
      </SimpleForm>
    </Create>
  );
};
